.home-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 87.4vh;

  .home-page-content {
    width: 100%;

    .search-bar-shell {
      display: flex;
      justify-content: flex-end;
      margin-top: 1%;
      margin-right: 5%;
    }

    .spinner-shell {
      display: flex;
      justify-content: center;
      margin-top: 10%;
    }

    .home-page-presentations {
      display: grid;
      grid-template-columns: auto auto;
      //grid-gap: 3%;
      padding: 2% 5% 5% 5%;

      .presentation-card {
        margin: 3%;
        .card-text {
          font-size: 30px;
          font-weight: bold;
        }

        .card-bottom {
          display: flex;
          font-weight: bold;

          .like-count {
            display: flex;
            height: 20px;
            align-items: center;
            margin-right: 10px;
          }
        }

        .fa-download {
          font-size: 15px;
        }
      }
    }

    @media only screen and (max-width: 576px) {
      .home-page-presentations {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: unset !important;

        .presentation-card {
          margin-bottom: 5% !important;
          .card-body{
            display: flex;
            flex-direction: column;
            .btn {
              margin-left: unset;
            }
          }
        }
      }
    }
  }

  .navbar {
    width: 100%;
  }

  .email {
    margin-top: 50px;
    width: 450px;
  }

  .pass {
    width: 150px;
  }

  .btn {
    margin: 20px
  }
}
