.presentation-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2%;
  min-height: 87.4vh;
  height: 100%;

  .qr-code-button {
    position: fixed;
    right: 25px;
    bottom: 25px;

    .qr-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 48px;
      height: 48px;

      .fa-qrcode {
        font-size: 2rem;
      }
    }
  }

  .answers-page-header {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    .answers-page-header-content {
      width: 90%;
      display: flex;

      .prev {
        display: flex;
        flex: 1;
      }

      .questions {
        display: flex;
        font-weight: bold;
        font-size: larger;
        align-items: center;
        justify-items: center;
      }

      .next {
        flex: 1;
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  .presentation-header {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 4%;
    font-size: 2.5rem;
    font-weight: bold;
  }

  @media only screen and (max-width: 576px) {
    .questions-page-content {
      grid-template-columns: 1fr !important;
      grid-gap: unset !important;
      margin-bottom: 26rem !important;

      .answer-card {
        margin-bottom: 5% !important;
      }

    }

    .no-records {
      margin-top: 2%;
      text-align: center;
      font-size: 1rem !important;
      font-weight: bold;
    }

    .answer-card {
      .card-body {
        .card-text {
          font-size: unset !important;
        }
      }
    }
  }

  .questions-page-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3%;
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 21rem !important;

    .answer-card {
      .card-body {
        .card-text {
          font-size: 2rem;
        }
      }

      .card-bottom {
        display: flex;
        flex-direction: row-reverse;

        .like-count {
          display: flex;
          height: 100%;
          align-items: center;
          margin-right: 10px;
        }

        .liked {
          color: red;
        }
      }
    }
  }

  .no-records {
    margin-top: 2%;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
  }

  .spinner-shell {
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
  }

}
