.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 87.4vh;

  .login-form-group {
    display: flex;
    flex-direction: column;
    width: 25%;
    align-items: center;
  }

  @media (max-width: 480px) {
    .login-form-group {
      width: 90%;
    }
  }

  .email {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 90%;
  }

  .pass {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    width: 90%;
  }

  .login-button-shell {
    display: flex;
    justify-content: center;

    .login-button {
      margin-top: 1em;
    }
  }

  .login-with-google-button {
    margin-top: 1em;
  }

  .register-button-shell {
    margin-top: 1em;
  }
}
