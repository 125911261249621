.qr-code-page-content {
  display: flex;
  height: 100%;
  max-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 87.4vh;

  .qr-code-buttons {
    .presentation-button {
      margin-right: 10px;
    }

    .fa-copy {
      font-size: 15px;
    }
  }

  .qr-code-generator {
    margin-bottom: 2%;
  }
}
