.presentation-creator {
  display: flex;
  flex-direction: column;
  padding: 30px;
  min-height: 87.4vh;


  .presentation-name {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-self: center;
    margin-bottom: 2%;
    padding: 10px;
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, .4);
    border-radius: 6px;

    .form-label {
      font-size: 23px;
    }
  }

  .topic-element {
    padding: 10px;
    display: flex;
    margin-bottom: 20px;
    height: 65px;
    align-items: center;
    box-shadow: 0 0 40px -10px rgba(0, 0, 0, .4);
    border-radius: 6px;

    .topic-element-number {
      margin-right: 1%;
    }

    .topic {
      display: flex;
      flex: 1;
      height: 40px;
    }

    .delete-item-button {
      display: none;
    }
  }

  .topic-element:hover {

    .delete-item-button {
      display: flex;
    }

    .topic {
      padding-right: 20px;
    }
  }

  .add-new-question-button-shell {
    display: flex;
    justify-content: center;

    .btn-primary {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      border-radius: 50%;
    }
  }

  .btn-continue-shell {
    display: flex;
    justify-content: flex-end;

    .btn-success {
      width: 15%;
      color: white;
      font-weight: bold;
    }

    .btn-success:hover:disabled {
      // TODO: fix this.
      cursor: not-allowed;
    }
  }


  @media only screen and (max-width: 576px) {
    .presentation-name {
      width: 100%;
    }
    .btn-continue-shell {
      margin-top: 10%;
      .btn-success {
        width: 50%;
      }
    }
  }
}
