.answers-page {
  padding-top: 3%;
  display: flex;
  flex-direction: column;
  height: 500px;
  min-height: 87.4vh;

  .spinner-shell {
    display: flex;
    justify-content: center;
  }

  .answers-page-header {
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-items: center;

    .answers-page-header-content {
      width: 90%;
      display: flex;

      .prev {
        display: flex;
        flex: 1;
      }

      .questions {
        display: flex;
        font-weight: bold;
        font-size: larger;
        align-items: center;
        justify-items: center;
      }

      .next {
        flex: 1;
        display: flex;
        flex-direction: row-reverse;
      }
    }
  }

  .question-header {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 4%;
    font-size: larger;
    font-weight: bold;
  }

  .answer-section {
    display: flex;
    justify-content: center;
    margin-bottom: 4%;

    .answer {
      width: 90%;
    }

    .form-label {
      display: flex;
      justify-content: center;
    }
  }

  .answer-buttons {
    display: flex;
    justify-content: space-around;
  }

  .answer-alert {
    margin: 5%;
  }
}
