.register-page {
  display: flex;
  justify-content: center;
  padding-top: 50px;
  min-height: 87.4vh;

  .input-element {
    margin-bottom: 20px;
  }
  .name {
    width: 450px;
  }

  .email {
    width: 450px;
  }

  .pass {
    width: 450px;
  }

  .register-button-shell {
    display: flex;
    justify-content: center;

    .register-button {
      width: 200px;
    }
  }
}
