.search-bar {
  display: flex;

  .search-bar-group {
    display: flex;

    .search-icon {
      display: flex;
      align-items: center;
      height: 100%;
      padding-right: 5px;
      padding-left: 5px;
      border: 1px solid #bdbdbd;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }

    input {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
    }
  }
}
