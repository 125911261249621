.questions-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-top: 2%;
  min-height: 87.4vh;

  .questions-page-header {
    display: flex;
    height: 15%;
    padding-left: 5%;

    .return-button {
      display: flex;
      align-items: center;
    }

    .question-header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: larger;
      font-weight: bold;
      width: 100%;
      padding-left: 2%;
      padding-right: 2%;
    }
  }

  @media only screen and (max-width: 576px) {
    .questions-page-content {
      grid-template-columns: 1fr !important;
      grid-gap: unset !important;

      .answer-card {
        margin-bottom: 5% !important;
      }
    }

    .no-records {
      margin-top: 2%;
      text-align: center;
      font-size: 1rem !important;
      font-weight: bold;
    }

    .answer-card {
      .card-body {
        .card-text {
          font-size: unset !important;
        }
      }
    }
  }

  .questions-page-content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 3%;
    padding-top: 2%;
    padding-left: 5%;
    padding-right: 5%;
    margin-bottom: 21rem !important;

    .answer-card {
      .card-body {
        .card-text {
          font-size: 2rem;
        }
      }

      .card-bottom {
        display: flex;
        flex-direction: row-reverse;

        .like-count {
          display: flex;
          height: 100%;
          align-items: center;
          margin-right: 10px;
        }

        .liked {
          color: red;
        }

        .like-icon:active {
          transform: translateY(4px);
        }
      }
    }
  }

  .no-records {
    margin-top: 2%;
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
  }

  .spinner-shell {
    display: flex;
    justify-content: center;
  }
}
